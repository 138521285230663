import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import { Chart } from 'chart.js'
import vClickOutside from 'v-click-outside'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import moment from 'moment'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsView',
  directives: {
    clickOutside: vClickOutside.directive
  }
})

export default class GtrLeadsView extends GtrSuper {
  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.LEADS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Leads Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  data () {
    return {
      dropdownDates: [],
      selectedDate: 'All',
      isBetweenDates: false,
      start_date: null,
      end_date: null,
      today_date: null,
      active_start_time_menu: false,
      active_end_time_menu: false,
      timeChartData: {
        dates: [],
        totalScans: [],
        uniqueScans: []
      },
      chartTotalScans: null,
      chartUniqueScans: null,
      loading: false,
      selectedDatesRange: [],
      tables: [],
      table: {
        search: '',
        headers: [
          { text: 'Exhibitor', align: 'start', sortable: true, value: 'full_name' },
          { text: 'Total Scans', align: 'start', sortable: true, value: 'stats.total_scans' },
          { text: 'Unique Scans', align: 'start', sortable: true, value: 'stats.unique_scans' }
        ],
        items: []
      },
      event_uuid: this.$route.params.event_uuid,
      rawData: null,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  }

  private async fetchLeadScans () {
    try {
      this.$data.loading = true
      this.$data.rawData = await this.$store.dispatch('leads/fetchLeadScans', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async mounted () {
    await this.fetchLeadScans()
    this.formatRawDataDates()
    this.setDropDownDates()
    this.setChartData()
    this.drawSectionCharts()
    this.setTableData()
  }

  setDropDownDates () {
    const sortedDates = [] as any
    this.$data.rawData.exhibitor_scans_by_date.forEach((element: any) => sortedDates.push(element[0]))
    this.$data.dropdownDates = sortedDates
    this.$data.dropdownDates.unshift('In Between')
    this.$data.dropdownDates.unshift('All')
  }

  setChartData () {
    const rawItems = this.$data.rawData.exhibitor_scans_by_date
    const dateFormatRegex = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/g
    // A Single Date Case
    if (dateFormatRegex.test(this.$data.selectedDate) === true) {
      this.$data.timeChartData.dates.push(this.$data.selectedDate)
      Object.values(rawItems).forEach((e: any) => {
        if (e[0] === this.$data.selectedDate) {
          this.$data.timeChartData.dates.push(e[0])
          this.$data.timeChartData.totalScans.push(e[1][0].stats.total_scans)
          this.$data.timeChartData.uniqueScans.push(e[1][0].stats.unique_scans)
        }
      })
    } else if (this.$data.selectedDate === 'In Between') {
      const rawItems = this.$data.rawData.exhibitor_scans_by_date
      Object.values(rawItems).forEach((e: any) => {
        if (this.$data.start_date <= e[0] && e[0] <= this.$data.end_date) {
          this.$data.timeChartData.dates.push(e[0])
          this.$data.timeChartData.totalScans.push(e[1][0].stats.total_scans)
          this.$data.timeChartData.uniqueScans.push(e[1][0].stats.unique_scans)
        }
      })
    } else if (this.$data.selectedDate === 'All') {
      const dates = [] as any
      const totalScans = [] as any
      const uniqueScans = [] as any
      Object.values(rawItems).forEach((elem: any) => dates.push(elem[0]))
      Object.values(rawItems).forEach((elem: any) => totalScans.push(elem[1][0].stats.total_scans))
      Object.values(rawItems).forEach((elem: any) => uniqueScans.push(elem[1][0].stats.unique_scans))
      this.$data.timeChartData.dates = dates
      this.$data.timeChartData.totalScans = totalScans
      this.$data.timeChartData.uniqueScans = uniqueScans
    }
  }

  getDatesBetween (startDateString: string, stopDateString: string) {
    const dateArray = [] as any
    let currentDate = moment(startDateString)
    const stopDate = moment(stopDateString)
    while (currentDate < stopDate) {
      dateArray.push(moment(currentDate).format('MM-DD-YYYY'))
      currentDate = moment(currentDate).add(1, 'days')
    }
    return dateArray
  }

  getIndexForScanDates (dates, datesBetween) {
    const datesIndexes = [] as any
    dates.map(element => {
      datesIndexes.push(datesBetween.indexOf(element))
    })
    return datesIndexes
  }

  drawChart (chartId, chartLabels, chartData, chartType, chartName) {
    let explicitDataset = []
    for (let i = 0; i < chartLabels.length - 1; i++) {
      explicitDataset = explicitDataset.concat(this.getDatesBetween(chartLabels[i], chartLabels[i + 1]))
    }
    explicitDataset = explicitDataset.concat(chartLabels[chartLabels.length - 1])

    const datesIndexes = this.getIndexForScanDates(chartLabels, explicitDataset)
    const chartDataScansValues = [...chartData]
    const explicitValues = Array(explicitDataset.length).fill(0)

    for (let index = 0; index < datesIndexes.length; index++) {
      const element = datesIndexes[index]
      if (chartDataScansValues.length > 0) {
        explicitValues[element] = chartDataScansValues.shift()
      }
    }

    const ctx = document.getElementById(chartId) as any
    const total = Object.values(chartData).reduce((a: any, b: any) => a + b, 0)
    const chartObjectName = chartId === 'time-total-scans-chart' ? 'chartTotalScans' : 'chartUniqueScans'

    if (this.$data[chartObjectName]) {
      this.destroyChart(this.$data[chartObjectName])
    }

    this.$data[chartObjectName] = new Chart(ctx, {
      type: chartType,
      data: {
        labels: explicitDataset,
        datasets: [
          {
            label: `${chartName}: ${total}`,
            data: explicitValues,
            backgroundColor: 'rgba(0, 108, 187, .3)',
            borderColor: '#006cbb',
            borderWidth: 3,
            spanGaps: true,
            showLine: true
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          onClick: (e) => e.stopPropagation()
        },
        // maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'day'
            }
          }]
        }
      }
    })
  }

  destroyChart (chart) {
    chart.destroy()
  }

  drawSectionCharts () {
    this.drawChart('time-total-scans-chart', this.$data.timeChartData.dates, this.$data.timeChartData.totalScans, 'line', 'Total Scans')
    this.drawChart('time-unique-scans-chart', this.$data.timeChartData.dates, this.$data.timeChartData.uniqueScans, 'line', 'Unique Scans')
  }

  cleanChartData () {
    this.$data.timeChartData.dates = []
    this.$data.timeChartData.totalScans = []
    this.$data.timeChartData.uniqueScans = []
  }

  onClickRowHandle (participant) {
    this.$router.push({
      name: 'level-two.modules.leads.date-breakdown.scans',
      params: { participant_uuid: participant.exhibitor.uuid }
    })
  }

  getTodayDate () {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    const todayString = mm + '-' + dd + '-' + yyyy
    return todayString
  }

  changeSelectedDate () {
    this.cleanChartData()
    this.cleanTableData()
    this.setTableData()
    this.setChartData()
    this.drawSectionCharts()
  }

  formatRawDataDates () {
    const rawItems = this.$data.rawData.exhibitor_scans_by_date
    Object.keys(rawItems).forEach(e => {
      const formatedDate = this.convertDatetimeToMMDDYYYY(e)
      rawItems[formatedDate] = rawItems[e]
      delete rawItems[e]
    })

    this.$data.rawData.exhibitor_scans_by_date = Object.entries(rawItems).sort(function (a, b) {
      const keyA = new Date(a[0])
      const keyB = new Date(b[0])
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  }

  setTableData () {
    const rawItems = this.$data.rawData.exhibitor_scans_by_date
    const tables = [] as any
    const table = {
      search: '',
      headers: [
        { text: 'Exhibitor', align: 'start', sortable: true, value: 'full_name' },
        { text: 'Total Scans', align: 'start', sortable: true, value: 'stats.total_scans' },
        { text: 'Unique Scans', align: 'start', sortable: true, value: 'stats.unique_scans' }
      ],
      items: [] as any
    }

    if (this.$data.selectedDate === 'All' || this.$data.selectedDate === 'In Between') {
      Object.values(rawItems).forEach((e: any) => {
        if ((this.$data.selectedDate === 'In Between' && this.$data.start_date <= e[0] && e[0] <= this.$data.end_date) ||
          (this.$data.selectedDate === 'All')) {
          const aTable = {
            search: '',
            headers: [
              { text: 'Exhibitor', align: 'start', sortable: true, value: 'full_name' },
              { text: 'Total Scans', align: 'start', sortable: true, value: 'stats.total_scans' },
              { text: 'Unique Scans', align: 'start', sortable: true, value: 'stats.unique_scans' }
            ],
            items: []
          } as any
          this.$data.selectedDatesRange.push(e[0])
          aTable.items.push(e[1][0])
          tables.push(aTable)
        }
      })
    } else {
      Object.values(rawItems).forEach((e: any) => {
        if (e[0] === this.$data.selectedDate) {
          this.$data.selectedDatesRange.push(e[0])
          table.items.push(e[1][0])
          tables.push(table)
          this.$data.table.items = rawItems[e[1]]
        }
      }
      )
    }
    this.$data.tables = tables
  }

  cleanTableData () {
    this.$data.selectedDatesRange = []
    this.$data.tables = []
  }

  // WATCHERS
  @Watch('start_date', { immediate: true })
  tableAndChartStartDate () {
    if (this.$data.start_date && this.$data.start_date) {
      this.$data.start_date = this.convertDatetimeToMMDDYYYY(this.$data.start_date)
      if (this.$data.start_date <= this.$data.end_date) {
        this.cleanChartData()
        this.cleanTableData()
        this.setTableData()
        this.setChartData()
        this.drawSectionCharts()
      }
    }
  }

  @Watch('end_date', { immediate: true })
  tableAndChartEndDate () {
    if (this.$data.start_date && this.$data.start_date) {
      this.$data.end_date = this.convertDatetimeToMMDDYYYY(this.$data.end_date)
      if (this.$data.start_date <= this.$data.end_date) {
        this.cleanChartData()
        this.cleanTableData()
        this.setTableData()
        this.setChartData()
        this.drawSectionCharts()
      }
    }
  }
}
